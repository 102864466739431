<template>
  <div class="admin-create">
    <div class="admin-navbar align-left">
      <button class="action-button" @click="$router.go(-1)">{{ $lang.app.back }}</button>
    </div>
    <div class="admin-form-wrapper">
      <div class="admin-form" id="application-form">
        <Form id="application-create" @submit="submit" :validation-schema="schema" ref="myForm">
          <h4 class="create-title">{{ $lang.app.applicant_info }}</h4>

          <div class="reg-step-form-item">
            <label class="recovery-label">{{ $lang.app.applicant_type_label }} <i>*</i></label>
            <div class="reg-step-1-select-container">
              <i class="bi-chevron-down"></i>
              <Field as="select" name="applicantKddDto.relationTypeId"
                     v-model="form.applicantKddDto.relationTypeId">
                <option value="" disabled>{{ $lang.app.select_from }}</option>
                <option v-for="relationType in helper.relationTypes" :key="relationType.id"
                        :value="relationType.id">{{ relationType.title }}
                </option>
              </Field>
            </div>
            <ErrorMessage name="applicantKddDto.relationTypeId" class="recovery-label-error"/>
          </div>

          <div v-if="form.applicantKddDto.relationTypeId && form.applicantKddDto.relationTypeId === 3"
               class="file-input reg-step-form-item">
            <label class="recovery-label">{{ $lang.app.legal_representative_file }}</label>
            <Field type="file" name="fileLegalRepresentative" accept="application/pdf"
                   @change="event => filesForm.fileLegalRepresentative = event.target.files[0]"/>
            <ErrorMessage name="fileLegalRepresentative" class="recovery-label-error"/>
          </div>

          <div class="reg-step-form-item">
            <label class="recovery-label">{{ $lang.app.kdd_status }} <i>*</i></label>
            <div class="reg-step-1-select-container">
              <i class="bi-chevron-down"></i>
              <Field as="select" name="kddStatus"
                     v-model="form.kddStatus">
                <option value="" disabled>{{ $lang.app.select_from }}</option>
                <option v-for="kdd_status in helper.kddStatuses" :key="kdd_status.value"
                        :value="kdd_status.value">{{ kdd_status.label }}
                </option>
              </Field>
            </div>
            <ErrorMessage name="kddStatus" class="recovery-label-error"/>
          </div>

          <div class="reg-step-form-item">
            <label class="recovery-label">{{ $lang.app.surname }} <i>*</i></label>
            <div class="reg-step-1-select-container with-letter-buttons">
              <Field name="applicantKddDto.surname" type="text"
                     v-maska="{ mask: 'Z*', tokens: { 'Z': { pattern: /[а-яА-ЯЁё ҢңӨөҮү-]/ }}}"
                     :title="$lang.app.only_cyrillic"
                     v-model="form.applicantKddDto.surname"/>
              <ExtraKyrgyzLetters @change="form.applicantKddDto.surname += $event"/>
            </div>
            <ErrorMessage name="applicantKddDto.surname" class="recovery-label-error"/>
          </div>

          <div class="reg-step-form-item">
            <label class="recovery-label">{{ $lang.app.name }} <i>*</i></label>
            <div class="reg-step-1-select-container">
              <Field name="applicantKddDto.name" type="text"
                     v-maska="{ mask: 'Z*', tokens: { 'Z': { pattern: /[а-яА-ЯЁё ҢңӨөҮү-]/ }}}"
                     :title="$lang.app.only_cyrillic"
                     v-model="form.applicantKddDto.name"/>
              <ExtraKyrgyzLetters @change="form.applicantKddDto.name += $event"/>
            </div>
            <ErrorMessage name="applicantKddDto.name" class="recovery-label-error"/>
          </div>

          <div class="reg-step-form-item">
            <label class="recovery-label">{{ $lang.app.middle_name }} </label>
            <div class="reg-step-1-select-container">
              <Field name="applicantKddDto.middleName" type="text"
                     v-maska="{ mask: 'Z*', tokens: { 'Z': { pattern: /[а-яА-ЯЁё ҢңӨөҮү-]/ }}}"
                     :title="$lang.app.only_cyrillic"
                     v-model="form.applicantKddDto.middleName"/>
              <ExtraKyrgyzLetters @change="form.applicantKddDto.middleName += $event"/>
            </div>
            <ErrorMessage name="applicantKddDto.middleName" class="recovery-label-error"/>
          </div>

          <div class="reg-step-form-item">
            <label class="recovery-label">{{ $lang.app.birth_date }} <i>*</i></label>
            <div class="reg-step-1-select-container">
              <Field name="applicantKddDto.dateOfBirth" v-slot="{ field }">
                <Datepicker
                    v-bind="field"
                    v-model="form.applicantKddDto.dateOfBirth"
                    :format="format"
                    locale="ru"
                    cancelText=""
                    v-maska="'##-##-####'"
                    selectText="Ок" text-input :textInputOptions="{enterSubmit: true, format: 'dd-MM-yyyy'}">
                </Datepicker>
              </Field>
            </div>
            <ErrorMessage name="applicantKddDto.dateOfBirth" class="recovery-label-error"/>
          </div>

          <div class="reg-step-form-item">
            <label class="recovery-label">{{ $lang.app.phone_number }} <i>*</i></label>
            <div class="reg-step-1-select-container">
              <Field name="applicantKddDto.phone" type="text" autocomplete="off"
                     v-maska="'+996(###)-##-##-##'"
                     placeholder="+996(XXX)-XX-XX-XX"
                     v-model="form.applicantKddDto.phone"/>
            </div>
            <ErrorMessage name="applicantKddDto.phone" class="recovery-label-error"/>
          </div>

          <div class="reg-step-form-item" ref="email">
            <label class="recovery-label">{{ $lang.app.email }} <i>*</i></label>
            <div class="reg-step-1-select-container">
              <Field name="applicantKddDto.email" type="email"
                     v-maska="{ mask: 'Z*', tokens: { 'Z': { pattern: /[^а-яА-Я]/ }}}"
                     v-model="form.applicantKddDto.email"/>
            </div>
            <ErrorMessage name="applicantKddDto.email" class="recovery-label-error"/>
          </div>

          <div class="reg-step-form-item">
            <label class="recovery-label">{{ $lang.app.privilege_type }} <i>*</i></label>
            <div class="reg-step-1-select-container select-control-wrapper">
              <i class="bi-chevron-down"></i>
              <Field name="applicantKddDto.privilegeTypeId" v-slot="{ field }">
                <Multiselect
                    v-bind="field"
                    v-model="form.applicantKddDto.privilegeTypeId"
                    :options="helper.applicantPrivilegeTypes"
                    :placeholder="$lang.app.select_from"
                    valueProp="id"
                    label="title"
                    :noOptionsText="$lang.app.list_empty"
                    :noResultsText="$lang.app.not_found">
                </Multiselect>
              </Field>
            </div>
            <ErrorMessage name="applicantKddDto.privilegeTypeId" class="recovery-label-error"/>
          </div>

          <div v-if="form.applicantKddDto.privilegeTypeId && form.applicantKddDto.privilegeTypeId !== 200"
               class="file-input reg-step-form-item">
            <label class="recovery-label">{{ $lang.app.privilege_file }}</label>
            <Field type="file" name="fileApplicantPrivilege" accept="application/pdf"
                   @change="event => filesForm.fileApplicantPrivilege = event.target.files[0]"/>
            <ErrorMessage name="fileApplicantPrivilege" class="recovery-label-error"/>
          </div>

          <br>

          <h4 class="create-title">{{ $lang.app.children_info }}</h4>

          <div class="reg-step-form-item">
            <label class="recovery-label">{{ $lang.app.child_citizenship }} <i>*</i></label>
            <div class="reg-step-1-select-container">
              <i class="bi-chevron-down"></i>
              <Field as="select" name="childKddDto.citizenship"
                     v-model="form.childKddDto.citizenship">
                <option value="" disabled>{{ $lang.app.select_from }}</option>
                <option v-for="citizenship in helper.childCitizenships" :key="citizenship.value"
                        :value="citizenship.value">{{ citizenship.label }}
                </option>
              </Field>
            </div>
            <ErrorMessage name="childKddDto.citizenship" class="recovery-label-error"/>
          </div>

          <div class="reg-step-form-item">
            <label class="recovery-label">{{ $lang.app.surname }} <i>*</i></label>
            <div class="reg-step-1-select-container">
              <Field name="childKddDto.surname" type="text"
                     v-maska="{ mask: 'Z*', tokens: { 'Z': { pattern: /[а-яА-ЯЁё ҢңӨөҮү-]/ }}}"
                     :title="$lang.app.only_cyrillic"
                     v-model="form.childKddDto.surname"/>
              <ExtraKyrgyzLetters @change="form.childKddDto.surname += $event"/>
            </div>
            <ErrorMessage name="childKddDto.surname" class="recovery-label-error"/>
          </div>

          <div class="reg-step-form-item">
            <label class="recovery-label">{{ $lang.app.name }} <i>*</i></label>
            <div class="reg-step-1-select-container">
              <Field name="childKddDto.name" type="text"
                     v-maska="{ mask: 'Z*', tokens: { 'Z': { pattern: /[а-яА-ЯЁё ҢңӨөҮү-]/ }}}"
                     :title="$lang.app.only_cyrillic"
                     v-model="form.childKddDto.name"/>
              <ExtraKyrgyzLetters @change="form.childKddDto.name += $event"/>
            </div>
            <ErrorMessage name="childKddDto.name" class="recovery-label-error"/>
          </div>

          <div class="reg-step-form-item">
            <label class="recovery-label">{{ $lang.app.middle_name }} </label>
            <div class="reg-step-1-select-container">
              <Field name="childKddDto.middleName" type="text"
                     v-maska="{ mask: 'Z*', tokens: { 'Z': { pattern: /[а-яА-ЯЁё ҢңӨөҮү-]/ }}}"
                     :title="$lang.app.only_cyrillic"
                     v-model="form.childKddDto.middleName"/>
              <ExtraKyrgyzLetters @change="form.childKddDto.middleName += $event"/>
            </div>
            <ErrorMessage name="childKddDto.middleName" class="recovery-label-error"/>
          </div>

          <div class="reg-step-form-item">
            <label class="recovery-label">{{ $lang.app.birth_date }} <i>*</i></label>
            <div class="reg-step-1-select-container">
              <Field name="childKddDto.dateOfBirth" v-slot="{ field }">
                <Datepicker
                    v-bind="field"
                    v-model="form.childKddDto.dateOfBirth"
                    :format="format"
                    locale="ru"
                    cancelText=""
                    v-maska="'##-##-####'"
                    selectText="Ок" text-input :textInputOptions="{enterSubmit: true, format: 'dd-MM-yyyy'}">
                </Datepicker>
              </Field>
            </div>
            <ErrorMessage name="childKddDto.dateOfBirth" class="recovery-label-error"/>
          </div>
          <div class="reg-step-form-item">
            <label class="recovery-label">{{ $lang.app.child_gender }} <i>*</i></label>
            <div class="reg-step-1-select-container">
              <i class="bi-chevron-down"></i>
              <Field as="select" name="childKddDto.gender"
                     v-model="form.childKddDto.gender">
                <option value="" disabled>{{ $lang.app.select_from }}</option>
                <option v-for="gender in helper.genders" :key="gender.value"
                        :value="gender.value">{{ gender.label }}
                </option>
              </Field>
            </div>
            <ErrorMessage name="childKddDto.gender" class="recovery-label-error"/>
          </div>


          <div v-if="form.childKddDto.citizenship === 'KG'" key="KG" class="double-input">
            <div class="reg-step-form-item">
              <label class="recovery-label">{{ $lang.app.birth_certificate_seria }}</label>
              <div class="reg-step-1-select-container"><i class="bi-chevron-down"></i>
                <Field as="select" name="childKddDto.birthCertificateRange"
                       v-model="form.childKddDto.birthCertificateRange">
                  <option value="" disabled>{{ $lang.app.select_from }}</option>
                  <option v-for="birthCertificateRange in helper.birthCertificateRanges" :key="birthCertificateRange"
                          :value="birthCertificateRange">{{ birthCertificateRange }}</option>
                </Field>
              </div>
              <ErrorMessage name="childKddDto.birthCertificateRange" class="recovery-label-error" />
            </div>
            <div class="reg-step-form-item">
              <label class="recovery-label">{{ $lang.app.birth_certificate_number }}</label>
              <div class="reg-step-1-select-container">
                <Field name="childKddDto.birthCertificateId" type="text"
                       v-maska="'#######'"
                       v-model="form.childKddDto.birthCertificateId"/>
              </div>
              <ErrorMessage name="childKddDto.birthCertificateId" class="recovery-label-error" />
            </div>
          </div>
          <div v-if="form.childKddDto.citizenship === 'FOREIGN'" key="FOREIGN" class="double-input">
            <div class="reg-step-form-item">
              <label class="recovery-label">{{ $lang.app.birth_certificate_seria }}</label>
              <div class="reg-step-1-select-container">
                <Field name="childKddDto.birthCertificateRange" type="text"
                       v-model="form.childKddDto.birthCertificateRange"/>
              </div>
              <ErrorMessage name="childKddDto.birthCertificateRange" class="recovery-label-error" />
            </div>
            <div class="reg-step-form-item">
              <label class="recovery-label">{{ $lang.app.birth_certificate_number }}</label>
              <div class="reg-step-1-select-container">
                <Field name="childKddDto.birthCertificateId" type="text"
                       v-model="form.childKddDto.birthCertificateId"/>
              </div>
              <ErrorMessage name="childKddDto.birthCertificateId" class="recovery-label-error" />
            </div>
          </div>

          <div  class="file-input reg-step-form-item">
            <label class="recovery-label">{{ $lang.app.kdd_file }} <i>*</i></label>
            <Field type="file" name="fileKdd" accept="application/pdf"
                   @change="event => filesForm.fileKdd = event.target.files[0]"/>
            <ErrorMessage name="fileKdd" class="recovery-label-error"/>
          </div>

          <div class="reg-step-form-item">
            <label class="recovery-label">{{ $lang.app.education_language }} <i>*</i></label>
            <div class="reg-step-1-select-container select-control-wrapper">
              <i class="bi-chevron-down"></i>
              <Field name="languageId" v-slot="{ field }">
                <Multiselect
                    v-bind="field"
                    v-model="form.languageId"
                    :options="helper.organizationGroups"
                    :placeholder="$lang.app.select_from"
                    valueProp="id"
                    label="title"
                    :searchable="true"
                    :noOptionsText="$lang.app.list_empty"
                    :noResultsText="$lang.app.not_found">
                </Multiselect>
              </Field>
            </div>
            <ErrorMessage name="languageId" class="recovery-label-error"/>
          </div>

          <div v-if="this.isApplicationInIncorrectKddApplications !== true" class="info-block">
            <div v-for="(infoItem, index) in info" :key="index">
              <div v-if="infoItem.success" class="info-message">
                <i class="bi-info-circle"></i>
                {{ infoItem.message }}
              </div>
              <div v-else class="exclamation-message">
                <i class="bi-exclamation-circle"></i>
                {{ infoItem.message }}
              </div>
            </div>
          </div>

          <div class="reg-step-btn">
            <button type="submit" :disabled="!isReadyToSubmit">{{ $lang.app.create }}</button>
          </div>
        </Form>
      </div>
    </div>
  </div>
</template>
<script>
import {maska} from 'maska'
import {Field, Form, ErrorMessage} from 'vee-validate';
import ExtraKyrgyzLetters from '../../../components/ExtraKyrgyzLetters.vue'
import * as yup from "yup";
import Datepicker from 'vue3-date-time-picker';
import 'vue3-date-time-picker/dist/main.css'
import moment from 'moment-timezone'
moment.tz.setDefault('Asia/Bishkek')
export default {
  components: {
    Field,
    Form,
    ErrorMessage,
    ExtraKyrgyzLetters,
    Datepicker
  },
  directives: {maska},
  data() {
    return {
      info: [],
      helper: {
        genders: [
          {
            label: this.$lang.app.male,
            value: 'MALE'
          },
          {
            label: this.$lang.app.female,
            value: 'FEMALE'
          }
        ],
        relationTypes: [],
        kddStatuses: [
          {
            label: this.$lang.app.status_kkd_true,
            value: '1'
          },
          {
            label: this.$lang.app.status_kkd_false,
            value: '0'
          }
        ],
        applicantPrivilegeTypes: [],
        childCitizenships: [
          {
            label: this.$lang.app.child_citizen_kg,
            value: 'KG'
          },
          {
            label: this.$lang.app.child_citizen_foreign,
            value: 'FOREIGN'
          }
        ],
        birthCertificateRanges: ['KP-X', 'KGZ01'],
        organizationGroups: [],
      },

      filesForm: {
        fileLegalRepresentative: null,
        fileApplicantPrivilege: null,
        fileKdd: null,
      },

      form: {
        applicantKddDto: {
          dateOfBirth: null,
          email: '',
          surname: '',
          name: '',
          middleName: '',
          phone: '',
          privilegeTypeId: null,
          relationTypeId: null,
        },
        childKddDto: {
          citizenship: null,
          surname: '',
          name: '',
          middleName: '',
          dateOfBirth: null,
          gender: null,
          birthCertificateRange: null,
          birthCertificateId: '',
        },
        organizationId: this.$store.getters.getOrganizationId,
        languageId: null,
        kddStatus: null,
      },

      schema: yup.object({
        applicantKddDto: yup.object({
          dateOfBirth: yup.mixed().required(),
          email: yup.string().required().email(),
          surname: yup.string().required(),
          name: yup.string().required(),
          privilegeTypeId: yup.mixed().required(),
          relationTypeId: yup.mixed().required(),
          phone: yup.string().required()
              .matches(/\+996\([0-9]{3}\)-[0-9]{2}-[0-9]{2}-[0-9]{2}/, {excludeEmptyString: true}),
        }),
        childKddDto: yup.object({
          citizenship: yup.mixed().required(),
          surname: yup.string().required(),
          name: yup.string().required(),
          dateOfBirth: yup.mixed().required(),
          gender: yup.mixed().required()
        }),
        languageId: yup.mixed().required(),
        kddStatus: yup.mixed().required()
      }),
      isApplicationInIncorrectKddApplications: false
    }
  },


  created() {
    this.setRelationTypes()
    this.setApplicantPrivilegeTypes()
    this.setOrganizationGroupLanguage()
  },
  computed: {
    isReadyToSubmit: function () {
      return (this.info.length && !this.info.find(item => {
        return !item.isReadyToSubmit
      })) || this.isApplicationInIncorrectKddApplications === true
    },
  },

  watch: {
    'form.languageId'() {
      if (this.form.applicantKddDto.privilegeTypeId) {
        this.info = []
        let isApplicationInIncorrectKddApplications = this.checkKddApplication();
        if (isApplicationInIncorrectKddApplications === false){
          this.getQuota()
        }else{
          this.info = []
          this.info.length = 0
        }
      }
      else {
        this.$refs.myForm.setFieldError('applicantKddDto.privilegeTypeId', this.$lang.app.must_be_filled);
        this.scrollMeTo('email')
      }
    }
  },

  methods: {
    scrollMeTo(refName) {
      let element = this.$refs[refName];
      let top = element.offsetTop;

      window.scrollTo(0, top);
    },
    formatter(value) {
      return moment(value).format('DD-MM-YYYY')
    },
    format(date) {
      const year = date.getFullYear();
      const month = date.getMonth() + 1 > 9 ? date.getMonth() + 1 : '0' + (date.getMonth() + 1);
      const day = date.getDate() > 9 ? date.getDate() : '0' + date.getDate();

      return `${day}-${month}-${year}`;
    },
    setRelationTypes() {
      this.$axios.get(`/relation-type/get?lang=${this.$lang.$current}`).then(({data}) => {
        this.helper.relationTypes = data
      })
    },
    setApplicantPrivilegeTypes() {
      this.$axios.get(`/privilege-type/list/by`, {
        params: {
          type: 'SCHOOL',
          code: 'applicant_privilege_type',
          lang: this.$lang.$current
        }
      }).then(({data}) => {
        this.helper.applicantPrivilegeTypes = data
      })
    },
    setOrganizationGroupLanguage() {
      this.$axios.get(`/lang/organization-group/${this.$store.getters.getOrganizationId}/organization`).then(({data}) => {
        this.helper.organizationGroups = data.map(item => ({
          id: item.id,
          title: this.$lang.$translate({
            ru: item.title,
            kg: item.titleKg
          })
        }))
      })
    },
    async getQuota() {
      this.$store.commit('startLoading')

      this.$axios.get('/organization/free-quota-info-for-kdd/', {
        params: {
          applicantPrivilegeId: this.form.applicantKddDto.privilegeTypeId,
          languageId: this.form.languageId,
          organizationId: this.$store.getters.getOrganizationId,
        }
      }).then(({data}) => {
        if(data.message) {this.info.push({
            success: data.success,
            message: data.message,
            isReadyToSubmit: data.success
          })
        }
      }).catch(error => {
        if(error.status === 404) {
          this.info.push({
            success: false,
            message: error.response.data.message,
            isReadyToSubmit: false
          })
        }
      }).finally(() => {
        this.$store.commit('stopLoading');
      })
    },

    checkKddApplication() {
      this.$store.commit('startLoading')
      this.$axios.post('/application/check-kdd-application', {
        ...this.form,
        applicantKddDto: {
          ...this.form.applicantKddDto,
          phone: this.form.applicantKddDto.phone.replace(/\D/g, ""),
          dateOfBirth:  moment(String(this.form.applicantKddDto.dateOfBirth)).format('YYYY-MM-DD')
        },
        childKddDto: {
          ...this.form.childKddDto,
          dateOfBirth:  moment(String(this.form.childKddDto.dateOfBirth)).format('YYYY-MM-DD')
        }
      }).then(({data}) => {
        this.isApplicationInIncorrectKddApplications = data.message === "true"
        return data.message === "true"
      }).catch(error => {
        if (error.response.status === 400) {
          // actions.setErrors(error.response.data.errors);
          this.$jump('#application-create', {
            duration: 200,
            easing: t => t,
          })
        }
      }).finally(() => {
        this.$store.commit('stopLoading')
      })

      return false
    },

    submit(values, actions) {

      if(!this.filesForm.fileKdd) {
        actions.setErrors({fileKdd: 'Обязательное поле!'});
        return;
      }
      this.$store.commit('startLoading')
      this.$axios.post('/application/add-kdd-child-application', {
        ...this.form,
        applicantKddDto: {
          ...this.form.applicantKddDto,
          phone: this.form.applicantKddDto.phone.replace(/\D/g, ""),
          dateOfBirth:  moment(String(this.form.applicantKddDto.dateOfBirth)).format('YYYY-MM-DD')
        },
        childKddDto: {
          ...this.form.childKddDto,
          dateOfBirth:  moment(String(this.form.childKddDto.dateOfBirth)).format('YYYY-MM-DD')
        }
      }).then(({data}) => {
        let formData = new FormData();
        formData.append('fileLegalRepresentative', this.filesForm.fileLegalRepresentative);
        formData.append('fileApplicantPrivilege', this.filesForm.fileApplicantPrivilege);
        formData.append('fileKdd', this.filesForm.fileKdd);

        this.$axios.post(`/application/${data.message}/add-kdd-child-application-files`,
            formData,
            {
              headers: {
                'Content-Type': 'multipart/form-data'
              },
              params: {
                relation_id: this.form.applicantKddDto.relationTypeId
              }
            })
            .then(() => {
              this.$snotify.success(this.$lang.app.resource_created_successfully);
              this.$router.push(`/admin/applications?status=ENROLLED`)
            })
            .catch(error => {
              console.log(error)
            });
      }).catch(error => {
        if (error.response.status === 400) {
          actions.setErrors(error.response.data.errors);
          this.$jump('#application-create', {
            duration: 200,
            easing: t => t,
          })
        }
      }).finally(() => {
        this.$store.commit('stopLoading')
      })
    }
  },
}

</script>
<style>
@import '../../../assets/styles/admin/create.css';
@import '../../../assets/styles/admin/navbar.css';
@import '../../../assets/styles/admin/form.css';
@import '../../../assets/styles/form.css';
@import '../../../assets/styles/multiselect.css';
@import '../../../assets/styles/date-time-picker.css';

.info-block {
  margin: 10px 0;
}
.info-block i {
  font-size: 30px;
  margin-right: 10px;
}
.info-block .info-message {
  color: dodgerblue;
  display: flex;
  align-items: center;
  background: rgba(76,154,199,0.15);
  border-radius: 5px;
  padding: 20px;
  font-size: 20px;
  margin: 30px 0 20px;
}
.info-block .exclamation-message {
  color: indianred;
  display: flex;
  align-items: center;
  background: rgba(76,154,199,0.15);
  border-radius: 5px;
  padding: 20px;
  font-size: 20px;
  margin: 30px 0 20px;
}
</style>