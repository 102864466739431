<template>
  <div class="admin-create">
    <div class="admin-navbar align-left">
      <button class="action-button" @click="$router.go(-1)">{{ $lang.app.back }}</button>
    </div>
    <div class="admin-form-wrapper">
      <div class="admin-form" id="application-form">
        <Form id="application-create" @submit="submit" :validation-schema="schema">
          <h4 class="create-title">{{ $lang.app.applicant_info }}</h4>

          <div class="reg-step-form-item">
            <label class="recovery-label">{{ $lang.app.applicant_type_label }} <i>*</i></label>
            <div class="reg-step-1-select-container">
              <i class="bi-chevron-down"></i>
              <Field as="select" name="privilegesDto.relationTypeId"
                     v-model="form.privilegesDto.relationTypeId">
                <option value="" disabled>{{ $lang.app.select_from }}</option>
                <option v-for="relationType in helper.relationTypes" :key="relationType.id"
                        :value="relationType.id">{{ relationType.title }}</option>
              </Field>
            </div>
            <ErrorMessage name="privilegesDto.relationTypeId" class="recovery-label-error" />
          </div>

          <div v-if="form.privilegesDto.relationTypeId && form.privilegesDto.relationTypeId === 3"
               class="file-input reg-step-form-item">
            <label class="recovery-label">{{ $lang.app.legal_representative_file }}</label>
            <Field type="file" name="fileLegalRepresentative" accept="application/pdf"
                   @change="event => filesForm.fileLegalRepresentative = event.target.files[0]"/>
            <ErrorMessage name="fileLegalRepresentative" class="recovery-label-error"/>
          </div>

          <div class="reg-step-form-item">
            <label class="recovery-label">{{ $lang.app.citizenship }} <i>*</i></label>
            <div class="reg-step-1-select-container">
              <i class="bi-chevron-down"></i>
              <Field as="select" name="applicantPersonalDataDto.citizenship"
                     v-model="form.applicantPersonalDataDto.citizenship">
                <option value="" disabled>{{ $lang.app.select_from }}</option>
                <option v-for="citizenship in helper.citizenships" :key="citizenship.value"
                        :value="citizenship.value">{{ citizenship.label}}</option>
              </Field>
            </div>
            <ErrorMessage name="applicantPersonalDataDto.citizenship" class="recovery-label-error" />
          </div>

          <div class="reg-step-form-item">
            <label class="recovery-label">{{ $lang.app.pin }} <i>*</i></label>
            <div class="reg-step-1-select-container">
              <Field name="applicantPersonalDataDto.pin" type="text"
                     v-maska="'##############'"
                     @change="setDateOfBirth($event.target.value)"
                     v-model="form.applicantPersonalDataDto.pin"/>
              <div class="tooltip-target">
                <i class="bi-question-circle"></i>
                <div class="tooltip-content">
                  <p>{{ $lang.app.id_card_des }}</p>
                  <p>{{ $lang.app.id_card_example }}</p>
                  <div class="passport-images">
                    <div class="passport-image">
                      <img src="../../../assets/images/pin-2017.png" alt="">
                      <span>{{ $lang.app.id_card }}</span>
                    </div>
                    <div class="passport-image">
                      <img src="../../../assets/images/pin-2004.png" alt="">
                      <span>{{ $lang.app.passport }}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <ErrorMessage name="applicantPersonalDataDto.pin" class="recovery-label-error" />
          </div>

          <div class="reg-step-form-item">
            <label class="recovery-label">{{ $lang.app.surname }} <i>*</i></label>
            <div class="reg-step-1-select-container with-letter-buttons">
              <Field name="applicantPersonalDataDto.surname" type="text"
                     v-maska="{ mask: 'Z*', tokens: { 'Z': { pattern: /[а-яА-ЯЁё ҢңӨөҮү-]/ }}}"
                     :title="$lang.app.only_cyrillic"
                     v-model="form.applicantPersonalDataDto.surname"/>
              <ExtraKyrgyzLetters @change="form.applicantPersonalDataDto.surname += $event"/>
            </div>
            <ErrorMessage name="applicantPersonalDataDto.surname" class="recovery-label-error" />
          </div>

          <div class="reg-step-form-item">
            <label class="recovery-label">{{ $lang.app.name }} <i>*</i></label>
            <div class="reg-step-1-select-container">
              <Field name="applicantPersonalDataDto.name" type="text"
                     v-maska="{ mask: 'Z*', tokens: { 'Z': { pattern: /[а-яА-ЯЁё ҢңӨөҮү-]/ }}}"
                     :title="$lang.app.only_cyrillic"
                     v-model="form.applicantPersonalDataDto.name"/>
              <ExtraKyrgyzLetters @change="form.applicantPersonalDataDto.name += $event"/>
            </div>
            <ErrorMessage name="applicantPersonalDataDto.name" class="recovery-label-error" />
          </div>

          <div class="reg-step-form-item">
            <label class="recovery-label">{{ $lang.app.middle_name }} </label>
            <div class="reg-step-1-select-container">
              <Field name="applicantPersonalDataDto.middleName" type="text"
                     v-maska="{ mask: 'Z*', tokens: { 'Z': { pattern: /[а-яА-ЯЁё ҢңӨөҮү-]/ }}}"
                     :title="$lang.app.only_cyrillic"
                     v-model="form.applicantPersonalDataDto.middleName"/>
              <ExtraKyrgyzLetters @change="form.applicantPersonalDataDto.middleName += $event"/>
            </div>
            <ErrorMessage name="applicantPersonalDataDto.middleName" class="recovery-label-error" />
          </div>

          <div class="reg-step-form-item">
            <label class="recovery-label">{{ $lang.app.birth_date }} <i>*</i></label>
            <div class="reg-step-1-select-container">
              <Field name="applicantPersonalDataDto.dateOfBirth" type="date"
                     v-model="form.applicantPersonalDataDto.dateOfBirth"/>
            </div>
            <ErrorMessage name="applicantPersonalDataDto.dateOfBirth" class="recovery-label-error" />
          </div>

          <div v-if="form.applicantPersonalDataDto.citizenship === 'KG'" key="KG"
               class="double-input">
            <div class="reg-step-form-item">
              <label class="recovery-label">{{ $lang.app.passport_seria }} <i>*</i></label>
              <div class="reg-step-1-select-container">
                <i class="bi-chevron-down"></i>
                <Field as="select" name="applicantPersonalDataDto.passportRange"
                       v-model="form.applicantPersonalDataDto.passportRange">
                  <option value="" disabled>{{ $lang.app.select_from }}</option>
                  <option v-for="passportRange in helper.passportRanges" :key="passportRange"
                          :value="passportRange">{{ passportRange }}</option>
                </Field>
              </div>
              <ErrorMessage name="applicantPersonalDataDto.passportRange" class="recovery-label-error" />
            </div>
            <div class="reg-step-form-item">
              <label class="recovery-label">{{ $lang.app.passport_number }} <i>*</i></label>
              <div class="reg-step-1-select-container">
                <Field name="applicantPersonalDataDto.passportId" type="text"
                       v-maska="'#######'"
                       v-model="form.applicantPersonalDataDto.passportId"/>
                <div class="tooltip-target">
                  <i class="bi-question-circle"></i>
                  <div class="tooltip-content tooltip-content-small">
                    <p>{{ $lang.app.passport_number_des }}</p>
                    <p>{{ $lang.app.passport_number_example }}</p>
                    <div class="passport-images">
                      <div class="passport-image">
                        <img src="../../../assets/images/id-2017.png" alt="">
                        <span>{{ $lang.app.id_card }}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <ErrorMessage name="applicantPersonalDataDto.passportId" class="recovery-label-error" />
            </div>
          </div>
          <div v-if="form.applicantPersonalDataDto.citizenship === 'FOREIGN'" key="FOREIGN"
               class="double-input">
            <div class="reg-step-form-item">
              <label class="recovery-label">{{ $lang.app.country_code }} <i>*</i></label>
              <div class="reg-step-1-select-container select-control-wrapper">
                <i class="bi-chevron-down"></i>
                <Field name="applicantPersonalDataDto.foreignCode" v-slot="{ field }">
                  <Multiselect
                      v-bind="field"
                      v-model="form.applicantPersonalDataDto.foreignCode"
                      :options="helper.countryCodes"
                      :placeholder="$lang.app.select_from"
                      valueProp="code"
                      label="title"
                      :searchable="true"
                      :noOptionsText="$lang.app.list_empty"
                      :noResultsText="$lang.app.not_found">
                  </Multiselect>
                </Field>
              </div>
              <ErrorMessage name="applicantPersonalDataDto.foreignCode" class="recovery-label-error" />
            </div>
            <div class="reg-step-form-item">
              <label class="recovery-label">{{ $lang.app.document_number }} <i>*</i></label>
              <div class="reg-step-1-select-container">
                <Field name="applicantPersonalDataDto.foreignDoc" type="text"
                       v-model="form.applicantPersonalDataDto.foreignDoc"/>
              </div>
              <ErrorMessage name="applicantPersonalDataDto.foreignDoc" class="recovery-label-error" />
            </div>
          </div>

          <div class="reg-step-form-item">
            <label class="recovery-label">{{ $lang.app.phone_number }} <i>*</i></label>
            <div class="reg-step-1-select-container">
              <Field name="applicantPersonalDataDto.phone" type="text" autocomplete="off"
                     v-maska="'+996(###)-##-##-##'"
                     placeholder="+996(XXX)-XX-XX-XX"
                     v-model="form.applicantPersonalDataDto.phone"/>
            </div>
            <ErrorMessage name="applicantPersonalDataDto.phone" class="recovery-label-error" />
          </div>

          <div class="reg-step-form-item">
            <label class="recovery-label">{{ $lang.app.applicant_email }} <i>*</i></label>
            <div class="reg-step-1-select-container">
              <Field name="applicantPersonalDataDto.email" type="email"
                     v-maska="{ mask: 'Z*', tokens: { 'Z': { pattern: /[^а-яА-Я]/ }}}"
                     v-model="form.applicantPersonalDataDto.email"/>
            </div>
            <ErrorMessage name="applicantPersonalDataDto.email" class="recovery-label-error" />
          </div>

          <div class="reg-step-form-item">
            <label class="recovery-label">{{ $lang.app.privilege_type }}</label>
            <div class="reg-step-1-select-container select-control-wrapper">
              <i class="bi-chevron-down"></i>
              <Field name="privilegesDto.applicantPrivilegeId" v-slot="{ field }">
                <Multiselect
                    v-bind="field"
                    v-model="form.privilegesDto.applicantPrivilegeId"
                    :options="helper.applicantPrivilegeTypes"
                    :placeholder="$lang.app.select_from"
                    valueProp="id"
                    label="title"
                    :noOptionsText="$lang.app.list_empty"
                    :noResultsText="$lang.app.not_found">
                </Multiselect>
              </Field>
            </div>
            <ErrorMessage name="privilegesDto.applicantPrivilegeId" class="recovery-label-error" />
          </div>

          <div v-if="form.privilegesDto.applicantPrivilegeId && form.privilegesDto.applicantPrivilegeId !== 200"
               class="file-input reg-step-form-item">
            <label class="recovery-label">{{ $lang.app.privilege_file }}</label>
            <Field type="file" name="fileApplicantPrivilege" accept="application/pdf"
                   @change="event => filesForm.fileApplicantPrivilege = event.target.files[0]"/>
            <ErrorMessage name="fileApplicantPrivilege" class="recovery-label-error"/>
          </div>

          <br>

          <h4 class="create-title">{{ $lang.app.children_info }}</h4>

          <div class="reg-step-form-item">
            <label class="recovery-label">{{ $lang.app.child_citizenship }} <i>*</i></label>
            <div class="reg-step-1-select-container">
              <i class="bi-chevron-down"></i>
              <Field as="select" name="childrenPersonalDataDto.citizenship"
                     v-model="form.childrenPersonalDataDto.citizenship">
                <option value="" disabled>{{ $lang.app.select_from }}</option>
                <option v-for="citizenship in helper.childCitizenships" :key="citizenship.value"
                        :value="citizenship.value">{{ citizenship.label}}</option>
              </Field>
            </div>
            <ErrorMessage name="childrenPersonalDataDto.citizenship" class="recovery-label-error" />
          </div>

          <div class="reg-step-form-item">
            <label class="recovery-label">{{ $lang.app.pin }} <i>*</i></label>
            <div class="reg-step-1-select-container">
              <Field name="childrenPersonalDataDto.pin" type="text"
                     v-maska="'##############'"
                     @change="setChildDateOfBirth($event.target.value)"
                     v-model="form.childrenPersonalDataDto.pin"/>
              <div class="tooltip-target">
                <i class="bi-question-circle"></i>
                <div class="tooltip-content tooltip-content-small">
                  <p>{{ $lang.app.birth_certificate_des }}</p>
                  <p>{{ $lang.app.birth_certificate_example }}</p>
                  <div class="passport-images">
                    <div class="passport-image">
                      <img src="../../../assets/images/birth-cert.png" alt="">
                      <span>{{ $lang.app.birth_certificate }}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <ErrorMessage name="childrenPersonalDataDto.pin" class="recovery-label-error" />
          </div>

          <div class="reg-step-form-item">
            <label class="recovery-label">{{ $lang.app.surname }} <i>*</i></label>
            <div class="reg-step-1-select-container">
              <Field name="childrenPersonalDataDto.surname" type="text"
                     v-maska="{ mask: 'Z*', tokens: { 'Z': { pattern: /[а-яА-ЯЁё ҢңӨөҮү-]/ }}}"
                     :title="$lang.app.only_cyrillic"
                     v-model="form.childrenPersonalDataDto.surname"/>
              <ExtraKyrgyzLetters @change="form.childrenPersonalDataDto.surname += $event"/>
            </div>
            <ErrorMessage name="childrenPersonalDataDto.surname" class="recovery-label-error" />
          </div>

          <div class="reg-step-form-item">
            <label class="recovery-label">{{ $lang.app.name }} <i>*</i></label>
            <div class="reg-step-1-select-container">
              <Field name="childrenPersonalDataDto.name" type="text"
                     v-maska="{ mask: 'Z*', tokens: { 'Z': { pattern: /[а-яА-ЯЁё ҢңӨөҮү-]/ }}}"
                     :title="$lang.app.only_cyrillic"
                     v-model="form.childrenPersonalDataDto.name"/>
              <ExtraKyrgyzLetters @change="form.childrenPersonalDataDto.name += $event"/>
            </div>
            <ErrorMessage name="childrenPersonalDataDto.name" class="recovery-label-error" />
          </div>

          <div class="reg-step-form-item">
            <label class="recovery-label">{{ $lang.app.middle_name }} </label>
            <div class="reg-step-1-select-container">
              <Field name="childrenPersonalDataDto.middleName" type="text"
                     v-maska="{ mask: 'Z*', tokens: { 'Z': { pattern: /[а-яА-ЯЁё ҢңӨөҮү-]/ }}}"
                     :title="$lang.app.only_cyrillic"
                     v-model="form.childrenPersonalDataDto.middleName"/>
              <ExtraKyrgyzLetters @change="form.childrenPersonalDataDto.middleName += $event"/>
            </div>
            <ErrorMessage name="childrenPersonalDataDto.middleName" class="recovery-label-error" />
          </div>

          <div class="reg-step-form-item">
            <label class="recovery-label">{{ $lang.app.birth_date }} <i>*</i></label>
            <div class="reg-step-1-select-container">
              <Field name="childrenPersonalDataDto.dateOfBirth" type="date"
                     v-model="form.childrenPersonalDataDto.dateOfBirth"/>
            </div>
            <ErrorMessage name="childrenPersonalDataDto.dateOfBirth" class="recovery-label-error" />
          </div>

          <div v-if="form.childrenPersonalDataDto.citizenship === 'KG'" key="KG" class="double-input">
            <div class="reg-step-form-item">
              <label class="recovery-label">{{ $lang.app.birth_certificate_seria }} <i>*</i></label>
              <div class="reg-step-1-select-container"><i class="bi-chevron-down"></i>
                <Field as="select" name="childrenPersonalDataDto.birthCertificateRange"
                       v-model="form.childrenPersonalDataDto.birthCertificateRange">
                  <option value="" disabled>{{ $lang.app.select_from }}</option>
                  <option v-for="birthCertificateRange in helper.birthCertificateRanges" :key="birthCertificateRange"
                          :value="birthCertificateRange">{{ birthCertificateRange }}</option>
                </Field>
              </div>
              <ErrorMessage name="childrenPersonalDataDto.birthCertificateRange" class="recovery-label-error" />
            </div>
            <div class="reg-step-form-item">
              <label class="recovery-label">{{ $lang.app.birth_certificate_number }} <i>*</i></label>
              <div class="reg-step-1-select-container">
                <Field name="childrenPersonalDataDto.birthCertificateId" type="text"
                       v-maska="'#######'"
                       v-model="form.childrenPersonalDataDto.birthCertificateId"/>
              </div>
              <ErrorMessage name="childrenPersonalDataDto.birthCertificateId" class="recovery-label-error" />
            </div>
          </div>
          <div v-if="form.childrenPersonalDataDto.citizenship === 'FOREIGN'" key="FOREIGN" class="double-input">
            <div class="reg-step-form-item">
              <label class="recovery-label">{{ $lang.app.birth_certificate_seria }} <i>*</i></label>
              <div class="reg-step-1-select-container">
                <Field name="childrenPersonalDataDto.birthCertificateRange" type="text"
                       v-model="form.childrenPersonalDataDto.birthCertificateRange"/>
              </div>
              <ErrorMessage name="childrenPersonalDataDto.birthCertificateRange" class="recovery-label-error" />
            </div>
            <div class="reg-step-form-item">
              <label class="recovery-label">{{ $lang.app.birth_certificate_number }} <i>*</i></label>
              <div class="reg-step-1-select-container">
                <Field name="childrenPersonalDataDto.birthCertificateId" type="text"
                       v-model="form.childrenPersonalDataDto.birthCertificateId"/>
              </div>
              <ErrorMessage name="childrenPersonalDataDto.birthCertificateId" class="recovery-label-error" />
            </div>
          </div>

          <div class="reg-step-form-item">
            <label class="recovery-label">{{ $lang.app.privilege_type }} <i>*</i></label>
            <div class="reg-step-1-select-container select-control-wrapper">
              <i class="bi-chevron-down"></i>
              <Field name="privilegesDto.childPrivilegeId" v-slot="{ field }">
                <Multiselect
                    v-bind="field"
                    v-model="form.privilegesDto.childPrivilegeId"
                    :options="helper.childPrivilegeTypes"
                    :placeholder="$lang.app.select_from"
                    valueProp="id"
                    label="title"
                    :noOptionsText="$lang.app.list_empty"
                    :noResultsText="$lang.app.not_found">
                </Multiselect>
              </Field>
            </div>
            <ErrorMessage name="privilegesDto.childPrivilegeId" class="recovery-label-error" />
          </div>

          <div v-if="form.privilegesDto.childPrivilegeId && form.privilegesDto.childPrivilegeId !== 1"
               class="file-input reg-step-form-item">
            <label class="recovery-label">{{ $lang.app.privilege_file }}</label>
            <Field type="file" name="fileChildPrivilege" accept="application/pdf"
                   @change="event => filesForm.fileChildPrivilege = event.target.files[0]"/>
            <ErrorMessage name="fileChildPrivilege" class="recovery-label-error"/>
          </div>

          <br>

          <div class="reg-step-form-item">
            <label class="recovery-label">{{ $lang.app.group }} <i>*</i></label>
            <div class="reg-step-1-select-container select-control-wrapper">
              <i class="bi-chevron-down"></i>
              <Field name="organizationGroupId" v-slot="{ field }">
                <Multiselect
                    v-bind="field"
                    v-model="form.organizationGroupId"
                    :options="helper.organizationGroups"
                    :placeholder="$lang.app.select_from"
                    valueProp="id"
                    label="title"
                    :searchable="true"
                    :noOptionsText="$lang.app.list_empty"
                    :noResultsText="$lang.app.not_found">
                </Multiselect>
              </Field>
            </div>
            <ErrorMessage name="organizationGroupId" class="recovery-label-error" />
          </div>

          <div class="reg-step-btn">
            <button type="submit">{{ $lang.app.create }}</button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>
<script>
import { maska } from 'maska'
import { Field, Form, ErrorMessage } from 'vee-validate';
import ExtraKyrgyzLetters from '../../../components/ExtraKyrgyzLetters.vue'
import * as yup from "yup";
export default {
  components: {
    Field,
    Form,
    ErrorMessage,
    ExtraKyrgyzLetters,
  },
  directives: { maska },
  data() {
    return {
      helper: {
        relationTypes: [],
        citizenships: [
          {
            label: this.$lang.app.citizen_kg,
            value: 'KG'
          },
          {
            label: this.$lang.app.citizen_other,
            value: 'FOREIGN'
          }
        ],
        passportRanges: ['AN', 'ID'],
        countryCodes: [],
        applicantPrivilegeTypes: [],
        childCitizenships: [
          {
            label: this.$lang.app.child_citizen_kg,
            value: 'KG'
          },
          {
            label: this.$lang.app.child_citizen_foreign,
            value: 'FOREIGN'
          }
        ],
        birthCertificateRanges: ['KP-X', 'KGZ01'],
        childPrivilegeTypes: [],
        organizationGroups: [],
      },

      filesForm: {
        fileLegalRepresentative: null,
        fileApplicantPrivilege: null,
        fileChildPrivilege: null,
      },

      form: {
        applicantPersonalDataDto: {
          citizenship: null,
          dateOfBirth: null,
          email: '',
          passportRange: null,
          passportId: '',
          foreignCode: null,
          foreignDoc: '',
          surname: '',
          name: '',
          middleName: '',
          pin: '',
          phone: '',
        },
        childrenPersonalDataDto: {
          citizenship: null,
          pin: '',
          surname: '',
          name: '',
          middleName: '',
          dateOfBirth: null,
          birthCertificateRange: null,
          birthCertificateId: '',
        },
        privilegesDto: {
          applicantPrivilegeId: null,
          childPrivilegeId: null,
          relationTypeId: null,
        },
        organizationGroupId: null,
      },

      schema: yup.object({
        applicantPersonalDataDto: yup.object({
          citizenship: yup.mixed().required(),
          dateOfBirth: yup.mixed().required(),
          email: yup.string().required().email(),
          passportRange: yup.mixed().when('citizenship', {
            is: 'KG',
            then: yup.mixed().required(),
            otherwise: yup.mixed().nullable(),
          }),
          passportId: yup.mixed().when('citizenship', {
            is: 'KG',
            then: yup.string().required().min(7),
            otherwise: yup.string().nullable(),
          }),
          foreignCode: yup.mixed().when('citizenship', {
            is: 'FOREIGN',
            then: yup.mixed().required(),
            otherwise: yup.string().nullable(),
          }),
          foreignDoc: yup.mixed().when('citizenship', {
            is: 'FOREIGN',
            then: yup.string().required(),
            otherwise: yup.string().nullable(),
          }),
          surname: yup.string().required(),
          name: yup.string().required(),
          pin: yup.string().min(14).
          required().matches(
            /[1,2][0-3][0-9][0-1][0-9][1,2][0,9][0-9]{7}/ 
          ),
        phone: yup.string().required()
              .matches(/\+996\([0-9]{3}\)-[0-9]{2}-[0-9]{2}-[0-9]{2}/, { excludeEmptyString: true }),
        }),
        childrenPersonalDataDto: yup.object({
          citizenship: yup.mixed().required(),
          pin: yup.string().min(14).
          required().matches(
            /[1,2][0-3][0-9][0-1][0-9][1,2][0,9][0-9]{7}/ 
          ),
          surname: yup.string().required(),
          name: yup.string().required(),
          dateOfBirth: yup.mixed().required(),
          birthCertificateRange: yup.mixed().when('citizenship', {
            is: (val) => ['KG', 'FOREIGN'].includes(val),
            then: yup.mixed().required(),
            otherwise: yup.mixed().nullable(),
          }),
          birthCertificateId: yup.mixed().when('citizenship', {
            is: (val) => ['KG', 'FOREIGN'].includes(val),
            then: yup.mixed().required(),
            otherwise: yup.mixed().nullable(),
          }),
        }),
        privilegesDto:yup.object({
          applicantPrivilegeId: yup.mixed().required(),
          childPrivilegeId: yup.mixed().required(),
          relationTypeId: yup.mixed().required(),
        }),
        organizationGroupId: yup.mixed().required(),
      }),
    }
  },
  created() {
    this.setRelationTypes()
    this.setCountryCodes()
    this.setApplicantPrivilegeTypes()
    this.setChildPrivilegeTypes()
    this.setOrganizationGroups()
  },
  methods: {
    setRelationTypes() {
      this.$axios.get(`/relation-type/get?lang=${this.$lang.$current}`).then(({data}) => {
        this.helper.relationTypes = data
      })
    },
    setCountryCodes() {
      this.$axios.get(`/country`).then(({data}) => {
        this.helper.countryCodes = data
      })
    },
    setApplicantPrivilegeTypes() {
      this.$axios.get(`/privilege-type/list/by`, {
        params: {
          type: this.application.type,
          code: 'applicant_privilege_type',
          lang: this.$lang.$current
        }
      }).then(({data}) => {
        this.helper.applicantPrivilegeTypes = data
      })
    },
    setChildPrivilegeTypes() {
      this.$axios.get(`/privilege-type/list/by`, {
        params: {
          type: this.application.type,
          code: 'child_privilege_type',
          lang: this.$lang.$current
        }
      }).then(({data}) => {
        this.helper.childPrivilegeTypes = data
      })
    },
    setOrganizationGroups() {
      this.$axios.get(`/organization-group/organization/${this.$store.getters.getOrganizationId}`).then(({data}) => {
        this.helper.organizationGroups = data.map(item => ({
          id: item.id,
          title: (item.name ? item.name + ' - ' : '') + this.$lang.$translate({
            ru: item.preschoolGroupType ? item.preschoolGroupType.nameRu : '',
            kg: item.preschoolGroupType ? item.preschoolGroupType.nameKg : ''})
        }))
      })
    },
    setDateOfBirth(value) {
      if(value.length > 9) {
        this.form.applicantPersonalDataDto.dateOfBirth = value.substring(5, 9) + '-' + value.substring(3, 5) + '-' + value.substring(1, 3)
      }
    },
    setChildDateOfBirth(value) {
      if(value.length > 9) {
        this.form.childrenPersonalDataDto.dateOfBirth = value.substring(5, 9) + '-' + value.substring(3, 5) + '-' + value.substring(1, 3)
      }
    },
    submit(values, actions) {
      this.$store.commit('startLoading')
      this.$axios.post('/application/add-exist-child-application', {
        ...this.form,
        applicantPersonalDataDto: {
          ...this.form.applicantPersonalDataDto,
          phone: this.form.applicantPersonalDataDto.phone.replace(/\D/g, "")
        },
      }).then(({data}) => {
        let formData = new FormData();
        formData.append('fileLegalRepresentative', this.filesForm.fileLegalRepresentative);
        formData.append('fileApplicantPrivilege', this.filesForm.fileApplicantPrivilege);
        formData.append('fileChildPrivilege', this.filesForm.fileChildPrivilege);

        this.$axios.post( `/application/${data.message}/add-exist-child-application-files`,
            formData,
            {
              headers: {
                'Content-Type': 'multipart/form-data'
              },
              params: {
                relation_id: this.form.privilegesDto.relationTypeId
              }
            })
            .then(() => {
              this.$snotify.success(this.$lang.app.resource_created_successfully);
              this.$router.push(`/admin/applications?status=ENROLLED`)
            })
            .catch(error => {
              console.log(error)
            });
      }).catch(error => {
        if(error.response.status === 400) {
          actions.setErrors(error.response.data.errors);
          this.$jump('#application-create', {
            duration: 200,
            easing: t => t,
          })
        }
      }).finally(() => {
        this.$store.commit('stopLoading')
      })
    }
  },
}
</script>
<style>
@import '../../../assets/styles/admin/create.css';
@import '../../../assets/styles/admin/navbar.css';
@import '../../../assets/styles/admin/form.css';
@import '../../../assets/styles/form.css';
@import '../../../assets/styles/multiselect.css';
</style>